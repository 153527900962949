import * as React from "react"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"

import MenuItem from "@mui/material/MenuItem"
import { Container, Grid, Link, Paper, styled, Typography } from "@mui/material"
import { FunctionComponent } from "react"
import { Icon } from "@iconify/react"

export interface Footer {
  copyrightOwner: string
  facebook?: string
  instagram?: string
  twitter?: string
  github?: string
  dribble?: string
  linkedin?: string
}

const LeftItem = styled(Paper)(() => ({
  textAlign: "left",
  minHeight: 30,
  fontSize: 30,
  display: "flex",
  border: 0,
  boxShadow: "none",
  backgroundColor: "#f5f5f5",
}))

const RightItem = styled(Paper)(() => ({
  minHeight: 30,
  fontSize: 30,
  display: "flex",
  justifyContent: "flex-end",
  border: 0,
  boxShadow: "none",
  backgroundColor: "#f5f5f5",
}))

export const Footer: FunctionComponent<Footer> = ({
  copyrightOwner,
  facebook,
  instagram,
  twitter,
  github,
  linkedin,
}) => {
  return (
    <Toolbar
      disableGutters
      sx={{
        py: 5,
        borderTop: 1,
        mt: 3,
        borderTopColor: "#eaeaea",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Container>
        <Grid
          container
          sx={{
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Grid item xs={7} md={5}>
            {/* <LeftItem> */}
            <Typography>
              © Copyright {new Date().getFullYear()} {copyrightOwner}. All
              rights reserved
            </Typography>
            {/* </LeftItem> */}
          </Grid>
          <Grid
            item
            xs={5}
            md={7}
            sx={{
              justifyContent: "flex-end",
              display: "flex",
              fontSize: 30,
            }}
          >
            {/* <RightItem> */}{" "}
            {facebook && (
              <Link href={facebook}>
                <Icon icon="ant-design:facebook-outlined" />
              </Link>
            )}
            {instagram && (
              <Link href={instagram}>
                <Icon icon="akar-icons:instagram-fill" />
              </Link>
            )}
            {twitter && (
              <Link href={twitter}>
                <Icon icon="ant-design:twitter-circle-filled" />
              </Link>
            )}
            {github && (
              <Link href={github}>
                <Icon icon="akar-icons:github-fill" />
              </Link>
            )}
            {linkedin && (
              <Link href={linkedin}>
                <Icon icon="akar-icons:linkedin-box-fill" />
              </Link>
            )}
            {/* </RightItem> */}
          </Grid>
        </Grid>
        {/* <Grid container direction="column" border={1} spacing={2}>
          <Grid item xs={3}>
            
          </Grid>
          <Grid item>
                     </Grid>
        </Grid> */}
      </Container>
    </Toolbar>
  )
}
