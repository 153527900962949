import React, { FunctionComponent, useMemo, useState } from "react"
import Helmet from "react-helmet"
import { useSiteMetadata } from "../hooks/useSiteMetadata"
import { MenuItem, NavBar } from "./header"
import { Footer as CustomFooter } from "./footer/navFooter"

import { Container, Divider } from "@mui/material"

import { PostSnippet } from "../../types"

import CssBaseline from "@mui/material/CssBaseline"
import { AppContext, ThemeModeContext } from "../../contexts"
import { ThemeProvider } from "@mui/material/styles"
import { SEO } from "../../components/seo/"
import { AppClient } from "../../client" //"..//client/"
import { DARK_MODE_THEME, LIGHT_MODE_THEME } from "../../../utils/constants"

import { getAppTheme } from "../../../styles/theme"

export interface LayoutProps {
  title: string
}

const menuItems: MenuItem[] = [
  {
    label: "HOME",
    href: "/",
  },
  {
    label: "ABOUT US",
    href: "/aboutus",
  },
]

const footer = {
  copyrightOwner: "Tecomatic Limited",
  linkedin: "https://www.linkedin.com/company/tecomatic-limited/",
}

export const Layout: FunctionComponent<LayoutProps> = ({ title, children }) => {
  // const { title } = useSiteMetadata()

  const appClient = new AppClient()
  const [mode, setMode] = useState<
    typeof LIGHT_MODE_THEME | typeof DARK_MODE_THEME
  >(LIGHT_MODE_THEME)

  const themeMode = useMemo(
    () => ({
      toggleThemeMode: () => {
        setMode(prevMode =>
          prevMode === LIGHT_MODE_THEME ? DARK_MODE_THEME : LIGHT_MODE_THEME
        )
      },
    }),
    []
  )

  const theme = useMemo(() => getAppTheme(mode), [mode])
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <AppContext.Provider value={appClient}>
        <ThemeModeContext.Provider value={themeMode}>
          <ThemeProvider theme={theme}>
            <SEO title={title} image="/images/tecomatic_logo.svg" />
            <CssBaseline />
            <Container sx={{ paddingBottom: 0.5 }}>
              <NavBar menuItems={menuItems} />
            </Container>
            <Divider
              sx={{
                padding: "2px",
                background:
                  "linear-gradient(to right, #6372ff 0%, #5ca9fb 100%)",
                border: 0,
              }}
            />

            {children}

            <CustomFooter {...footer} />
          </ThemeProvider>
        </ThemeModeContext.Provider>
      </AppContext.Provider>
    </>
  )
}

export default Layout
