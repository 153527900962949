import * as React from "react"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"

import MenuItem from "@mui/material/MenuItem"
import { Button, Grid, Link, Paper, styled } from "@mui/material"
import { FunctionComponent } from "react"
import { StaticImage } from "gatsby-plugin-image"

export interface DropdownItem {
  label: string
  description: string
  icon: JSX.Element
  href: string
}

export interface MenuItemWithDropdown {
  label: string
  dropdownItems: DropdownItem[]
}

export interface MenuItemWithoutDropdown {
  label: string
  href: string
}

export type MenuItem = MenuItemWithoutDropdown

export interface Header {
  menuItems: MenuItem[]
}

const LeftItem = styled(Paper)(() => ({
  textAlign: "left",
  fontSize: 30,
  display: "flex",
  border: 0,
  boxShadow: "none",
  backgroundColor: "#f5f5f5",
}))

const RightItem = styled(Paper)(() => ({
  fontSize: 30,
  boxShadow: "none",
  backgroundColor: "#f5f5f5",
}))

export const NavBar: FunctionComponent<Header> = ({ menuItems }) => {
  return (
    <Toolbar disableGutters>
      <Grid
        container
        spacing={2}
        sx={{
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Grid item xs={8} md={3} sm={5}>
          <Link href="/">
            <StaticImage
              src="../../../../static/images/tecomatic_logo.svg"
              alt="logo"
              placeholder="tracedSVG"
              objectFit="cover"
            />
          </Link>
        </Grid>
        <Grid item xs={4} md={9} sm={7}>
          <Box
            sx={{
              fontSize: 16,
              justifyContent: "flex-end",
              alignItems: "center",
              display: { xs: "none", md: "flex" },
              "& > :not(style) + :not(style)": {
                ml: 4,
              },
            }}
          >
            {menuItems.map((menuItem, index) => (
              <Link
                key={index}
                href={menuItem.href}
                sx={{
                  textDecoration: "none",
                  color: "#555",
                  "&:hover": {
                    borderBottom: 3,
                    borderImage:
                      "linear-gradient(to right, #6372ff 50%, #5ca9fb 100%)",
                    borderImageSlice: 1,
                    height: 25,
                  },
                }}
              >
                {menuItem.label}
              </Link>
            ))}
            <Button
              variant="contained"
              sx={{
                borderColor:
                  "linear-gradient(to right, #6372ff 50%, #5ca9fb 100%)",
                borderRadius: "25px 5px",
                outline: "none",
                "&:active": {
                  outline: "none",
                },
                "&:focus": {
                  outline: "none",
                },
              }}
              href="/contactus"
            >
              Contact Us
            </Button>
            <Link
              key="phonenumber"
              href="#"
              sx={{
                textDecoration: "none",
                color: "#555",
                "&:hover": {
                  borderBottom: 3,
                  borderImage:
                    "linear-gradient(to right, #6372ff 50%, #5ca9fb 100%)",
                  borderImageSlice: 1,
                  height: 25,
                },
              }}
            >
              +44 (0) 7871582620
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Toolbar>
  )
}
